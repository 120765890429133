import React from 'react';
import ReactPlayer from 'react-player'

const VIDEO_WIDTH = 1920;
const VIDEO_HEIGHT = 1080;

const VideoPlayer = (props) => {
    return ( 
        <div className='player-wrapper'>
            <ReactPlayer
                className='react-player'
                url={props.URL}
                controls={props.controls}
                playing={true}
                loop={true}
                muted={props.mute}
                width={props.VIDEO_WIDTH}
                height={props.VIDEO_HEIGHT}
                style={{objectFit: 'cover'}}
            />
        </div>
     );
}
 
export default VideoPlayer;