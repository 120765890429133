import React, {useState, useEffect} from 'react';
import styled from 'styled-components';


const ContentHeader = styled.header`
    width: 100%;
    height: 100px;
    padding-top: 55px;
    display: flex;
    position: fixed;
    z-index: 999999;
    background-color: transparent;
    transition: height 0.5s ease, background-color 0.5s ease;
    @media (max-width: 550px) {
        padding-top: 40px;
        height: auto;
    }
    #services{
        display: flex;
        align-items: center;
        svg{
            margin: 5px 0 0 5px;
        }
    }
    .phone{
        svg{
            margin-right: 7px;
        }
    }
    &.back_menu{
        background-color: white;
        height: 70px;
        /* box-shadow: 0px 0px 5px 0px #b1b1b1; */
        -webkit-box-shadow: 0px 2px 2px 0px rgba(0,0,0,0.23);
        -moz-box-shadow: 0px 2px 2px 0px rgba(0,0,0,0.23);
        box-shadow: 0px 2px 2px 0px rgba(0,0,0,0.23);
        padding-top: 0;
        align-items: center;
        .logo{
            img{
                width: 120px;
            }
        }
        ul{
            .activeService{
                    a{
                        /* color:  #C0C0C0 !important; */
                        color: black !important;
                    }
                }
            a{
                color: #C0C0C0 !important;
                @media (max-width: 550px) {
                    color: black !important;
                }
                &:hover{
                    color: black !important;
                }
            }
            li{
                /* color: black !important; */
                color: #C0C0C0 !important;
                &:hover{
                    /* color: #C0C0C0 !important; */
                    color: black !important;
                }
                ul{
                    background-color: white;
                    a{
                        color: black !important;
                        &:hover{
                            color: black !important;
                        }
                    }
                }
            }
            .phone{
                color: black !important;
                &:hover{
                    color:  #C0C0C0 !important;
                }
            }
        }
        .activeli{
            color: black !important;   
        }
        .btn-toggle {
            span{
                background-color: black;
                &::before, &::after {
                    background-color:black;
                }
            }
        }
    }
    .flecha_li{
        height: 15px; width: 15px;
        border: 1px solid white;
        border-width: 1.8px 1.8px 0 0;
        transform: rotate(135deg);
    }
    .logo{
        width: 30%;
        display: flex;
        align-items: center;
        padding-left: 75px;

        @media (max-width: 1024px) {
            padding-left: 40px;
        }
        img{
            width: 122px;
            transition: width 0.5s ease;
            @media (max-width: 550px) {
                width: 90px;
            }
        }
    }
    .menu{
        width: 70%;
        display: flex;
        justify-content: flex-end;
        padding-right: 75px;

        @media (max-width: 1024px) {
            flex-direction: column;
            background-color: white;
            justify-content: space-evenly;
            position: absolute;
            top: 0;
            width: 100%;
            transform: translateX(1000px);
            transition: transform 0.5s ease;
            padding-right: 0;
            padding: 80px 0 20px 40px;
            height: 100vh;
        }
        @media (max-width: 550px) {
            transform: translateX(500px);
        }
        @media (max-width: 1024px) and (orientation:landscape) {
            transform: translateX(1000px);
        }
        /* @media (max-height: 667px) {
            overflow: scroll;
        } */
        ul{
            display: flex;
            align-items: center; 
            max-width: 650px;
            a{
                color: white;
                text-decoration: none;
                border-bottom: 4px solid #FFFF02;
                align-items: center;
                display: flex;
                font-family: "HelveticaNeue";
                font-size: 20px;
                margin-top: 6px;
                padding-bottom: 5px;
                &:hover{
                    color: #C0C0C0;
                }
                img{
                    width: 20px;
                    margin-right: 10px;
                }
            }
            .activeService{
                    a{
                        color: white;
                    }
                }
            li{
                font-family: "HelveticaNeue";
                font-weight: 400;
                list-style: none;
                color: #C0C0C0;
                font-size: 20px;
                position: relative;
                cursor: pointer;
                margin-right: 30px;
                img{
                    padding: 0 5px;
                }
                a{
                            border: none;
                            color: #C0C0C0;
                            &:hover{
                                color: white;
                            }
                        }
                ul{
                    position: absolute;
                    height: 0;
                    bottom: 0;
                    overflow: hidden;
                    left: -5px;
                    width: 155px;
                    align-items: flex-start;
                    display: flex;
                    flex-direction: column;
                    transition: height 0.8s ease, bottom 0.8s ease;
                    li{
                        padding: 5px 5px;
                        display: flex;
                        flex-direction: column;
                        a{
                            border: none;
                            color: #C0C0C0;
                            &:hover{
                                color: white;
                            }
                        }
                    }
                }
                &:hover{
                    color: white;
                    ul{
                        height: 270px;
                        bottom: -275px;
                    }
                }
            }
            @media (max-width: 1200px) {
                    width: 80%;
            }
            @media (max-width: 1024px) {
                flex-direction: column;
                height: 60%;
                align-items: center;
                opacity: 0;
                transition: opacity 0.2s ease;
                align-items: flex-start;
                li{
                    font-family: "NeueHaasDisplayMedium";
                    font-size: 30px;
                    color: black;
                    padding: 0 0 15px;
                    a{
                        font-family: "NeueHaasDisplayMedium";
                        font-size: 30px;
                        margin: 0;
                        padding: 0;
                        color: black;
                    }
                }
            }
            @media (max-width:550px) {
                height: auto;
            }
        }
        .activeli{
            color: white;   
        }
        .bottom{
            width: 80%;
            a{
            color: black;
            font-family: "NeueHaasDisplayMedium";
            font-size: 30px;
            text-decoration:none;
            }
            @media (max-height: 667px) {
                margin-top: 50px
            }
            .social{
                @media (max-width: 1024px) {
                   margin: 10px 0 0px;
                    display: flex;
                    img{
                        width: 20px;
                        margin-right: 10px;
                    }
                }
            }
        }
        .city{
            display: flex;
            align-items: flex-end;
            width: 100%;
            height: 100px;
        }
    }
    .icon_menu{
        position: absolute;
        right: 50px;
        /* top: 10px; */
        display: none;
        @media (max-width: 1024px) {
            display: block;
        }
        @media (max-width: 550px) {
           right: 40px;
        }
    }
    .btn-toggle {
        width: 50px;
        height: 30px;
        margin-right: 0px !important;
        cursor: pointer;
        position: relative;
        span {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            display: block;
            height: 2.5px;
            width: 60%;
            background-color: white;
            transition: width .5s;
            &::before, &::after {
                content: '';
                width: 100%;
                height: 85%;
                background-color:white;
                position: absolute;
                left: 0;
                transition: width .5s;
            }
    
            &::before {
                top: -10px;
            }
            &::after {
                bottom: -10px;
            }
            &::before, &::after {
                transition: all .3s;
            }
        }
    }
`
const ContentPortada = styled.div`
    width: 100%;
    height: 92vh;
    min-height: 600px;
    display: flex;
    align-items: flex-end;
    position: relative;
    overflow: hidden;
    @media (max-width:1620px) {
        max-height: 780px;
    }
    @media (max-width:1435px) {
        max-height: 680px;
    }
    @media (max-width: 1024px) {
        max-height: 800px;
    }
    @media (max-width: 550px) {
        height: 100vh;
        max-height: fit-content;
    }
    .background-black{
        width: 100%;
        height: 100%;
        position: absolute;
        background: rgba(0, 0, 0, 0.4);
    }
    .player-wrapper{
        justify-content: flex-end;
        @media (max-width: 550px) {
            justify-content: center;
            align-items: flex-end;
        }
    }
    video{
        object-fit: cover;
        object-position: center;
        width: 100%;
        height: 100%;
        @media (min-width: 1600px) {
            object-fit: none;
        }
        @media (max-width: 550px) {
            object-position: -800px;
        }
        @media (max-width: 380px) {
            object-position: -700px;
        }
    }
    .slogan{
        position: absolute;
        width: 667px;
        left: 100px;
        top: 52%;
        padding-bottom: 50px;
        @media (min-width: 1600px) {
            /* width: 45%; */
            top: 55%;
            width: 700px;
        }
        @media (max-width: 1024px) and (orientation:landscape) {
            top: 30%;
        }
        @media (max-width: 1024px) {
            left: 40px;
            width: 615px;
            top: 55%;
        }
        @media (max-width: 550px) {
            left: 40px;
            width: 74%;
        }
        @media (max-width: 390px) {
            top: 45%;
            width: 76%;
        }
        h1{
            font-family: "NeueHaasDisplayMedium";
            color: white;
            font-size: 57px;
            line-height: 62px;
            letter-spacing: 0.02em;
            @media (min-width: 1600px) {
                font-size: 60px;
            }
            @media (max-width: 1024px) {
                font-size: 52px;
                line-height: 55px;
            }
            @media (max-width: 550px) {
                font-size: 36px;
                line-height: 40px;
            }
            @media (max-width:390px) {
                font-size: 33px;
            }
        }
    }
    .redes{
        position: absolute;
        left: 100px;
        padding-bottom: 50px;
        @media (min-width: 1600px) {
            padding-bottom: 100px;
        }

        @media (max-width: 1024px) {
            left: 40px;
            padding-bottom: 60px;
        }
        @media (max-width: 1024px) and (orientation:landscape) {
            padding-bottom: 50px;
        }
        @media (max-width: 550px) {
            left: 40px;
            padding-bottom: 90px;
        }
        a{
            /* font-weight: 600; */
            img{
                width: 25px;
            }
        }
    }
`
const ContentConcepto = styled.div`
    width: 100%;
    height: 550px;
    margin-top:  1.75rem;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
    padding: 0 100px;
    background-color: #F1F0EB;
    @media (max-width: 1350px) {
        padding: 0 50px;
    }
    @media (max-width: 1024px) {
        flex-direction: column;
        align-items: flex-start;
        height: auto;
        padding: 0 40px;
    }
    @media (max-width: 550px) {
        height: auto;
        padding: 0 40px;
        flex-direction: column;
        margin-top:  1.5rem;
    }
    .left{
        width: 50%;
        height: 100%;
        padding: 120px 0 120px 100px;
        @media (max-width: 1024px) {
            display: none;
        }
        position: relative;
        .swiper{
            height: 80%;
        }
        .swiper-slide {
            display: flex;
            align-items: center;
        }
        .referencia_concept{
            height: 100%;
            width: 97%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: flex-start;
            @media (min-width: 1600px) {
                width: 93%;
            }
            p{
                color: #6C6C6C;
                position: relative;
                font-weight: normal;
                padding-bottom: 15px;
            }
            span{
                color: #6C6C6C;
                font-weight: 700;
            }
        }
    }
    .right{
        width: 50%;
        height: 100%;
        padding: 120px 0;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        position: relative;
        /* @media (max-width: 1024px) {
            height: 40%;
        } */
        @media (max-width: 1024px) {
            width: 100%;
            height: 100%;
            height: 400px;
            padding: 40px 0;
            justify-content: flex-start;
        }
        .top{
            width: 89%;
            @media (min-width: 1600px) {
                width: 68%;
            }
            @media (max-width: 1024px) {
                display: flex;
                flex-direction: row;
                width: 100%;
                margin-bottom: 20px;
            }
        }
        .linea{
            width: 100px;
            height: 1px;
            background-color: black;
            margin-top: 30px;
        }
        h2{
            max-width: 700px;
            color: black;
            padding: 0;
            font-family: "NeueHaasDisplayMedium";
            font-weight: normal;
            overflow-wrap: break-word;
        }
        h3{
            color: black;
            font-family: "HelveticaNeue";
            font-weight: normal;
            overflow-wrap: break-word;
        }
        p{
            
            width: 85%;
            color: #6C6C6C;
            @media (min-width: 1600px) {
                width: 65%;
            }
            @media (max-width:550px) {
                width: 90%;
            }
            @media (max-width:390px) {
                width: 100%;
            }
        }
    }
`
const ContentServices = styled.div`
    width: 100%;
    height: 830px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    height: calc(56.25vw);
    background-color: #E6E6E6;
    overflow: hidden;
    .background-black{
        width: 100%;
        height: 100%;
        position: absolute;
        background: rgba(0, 0, 0, 0.4);
    }
    @media (max-width: 1024px) {
        height: 830px;
    }
    img{
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    video{
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    h2,h3,p{
        color: white;
    }
    .texto{
        position: absolute;
        left: 100px;
        @media (max-width: 1350px) {
            left: 80px;
        }
        @media (max-width: 1024px) {
            left: 40px;
            top: 100px;
        }
        h2{
            font-size: 40px;
            font-family: "NeueHaasDisplayMedium";
            padding: 0;
            letter-spacing: normal;
            @media (max-width: 550px) {
               font-size: 30px;
            }
            @media (max-width:390px) {
                font-size: 26px;
            }
        }
        p{
            font-size: 38px;
            line-height: 45px;
            margin-top: 12px;
            @media (max-width: 550px) {
               font-size: 30px;
               line-height: 35px;
            }
            @media (max-width:390px) {
                font-size: 26px;
                line-height: 30px;
            }
        }
    }
    .servicios{
        position: absolute;
        right: 100px;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        @media (max-width: 1350px) {
            right: 80px;
        }
        @media (max-width: 1024px) {
            right: auto;
            left: 40px;
            top:  320px;
            align-items: flex-start;
        }
        h3{
            font-size: 55px;
            line-height: 55px;
            cursor: pointer;
            border-bottom: 4px solid transparent;
            padding-left: 0;
            transition: padding-left 0.5s ease;
            font-family: "HelveticaNeue";
            padding-bottom: 7px;
            padding-top: 5px;
            @media (max-width: 1024px) {
                font-size: 40px;
                line-height: 45px;
            }
            @media (max-width:390px) {
                font-size: 36px;
                line-height: 40px;
            }
            &:hover{
                border-bottom: 4px solid #FFFF02;
            }
        }
        p{
            margin-bottom: 30px;
            font-size: 30px;
            @media (max-width: 1024px) {
                font-size: 20px;
            }
        }
       
        .active{
            border-bottom: 4px solid #FFFF02;
            padding-bottom: 7px;
        }
    }
`
const Video = styled.div`
    width: 100%;
    padding: 0;
    display: flex;
    flex-direction: column;
    background-color: #F1F0EB;
    cursor: pointer;
    @media (min-width: 1600px) {
        padding: 0 100px;
    }
    /* @media (max-width: 1024px) {
        height: 40vh;
    } */
    @media (max-width: 1024px) {
        padding: 0;
        height: auto;
        justify-content: space-evenly;
    }
    h2{
        font-size: 40px;
        padding: 30px 0;
        @media (max-width: 550px) {
            font-size: 24px;
            padding: 10px 0;
        }
    }
    .video{
        width: 100%;
        position: relative;
        @media (max-width: 1024px) {
            height: 75%;
        }
        @media (max-width:550px) {
            height: 70%;
        }
        img{
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
        a{
            display: flex;
            align-items: center;
            
            h2{
                font-family: "NeueHaasDisplayMedium";
                position: absolute;
                color: white;
                font-size: 50px;
                left: 100px;
                z-index: 1;
                @media (max-width: 1024px) {
                    font-size: 45px;
                    left: 20px;
                }
                @media (max-width: 550px) {
                    font-size: 18px;
                    left: 20px;
                }
            }
        }
        .show-enter-done {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            z-index: 9;
            iframe{
                width: 100%;
                height: 100%;
            }
        }
    }
`
const ContentIndustries = styled.div`
    width: 100%;
    height: 150px;
    padding: 0 50px;
    background-color: black;
    @media (min-width: 1600px) {
        padding: 0 100px;
    }
    @media (max-width: 550px) {
        height: 100vh;
        padding: 0 10px;
    }
`
const ContentStudio = styled.div`
    width: 100%;
    padding: 120px 100px;
    background-color: #F1F0EB;
    @media (max-width: 1350px) {
        padding: 120px 50px;
    }
    @media (max-width: 1024px) {
        padding: 40px;
    }
    h2{
        padding: 0 0 25px 0;
        color: black;
        font-family: "NeueHaasDisplayMedium";
        display: flex;
        flex-direction: column;
        &::after{
            content: ' ';
            margin-top: 10px;
            width: 100px;
            height: 2px;
            background-color: black;
        }
        
    }
    .studio{
        p{
            color: #6C6C6C;
            width: 915px;
            @media (max-width: 1024px) {
               width: 100%;
            }
            /* @media (max-width: 1362px) {
                font-size: 21px;
                line-height: 27px;
            } */
        }
    }
`
const ContentProcess = styled.div`
    width: 100%;
    padding: 0 50px;
    background-color: #F1F0EB;
    display: flex;
    flex-direction: column;
    padding: 120px 0;
    @media (max-width: 1024px) {
        padding: 0;
    }
    h2{
        padding: 0 0 25px 0;
        color: black;
        /* font-size: 40px; */
        font-family: "NeueHaasDisplayMedium";
        display: flex;
        flex-direction: column;
        &::after{
            content: ' ';
            margin-top: 10px;
            width: 100px;
            height: 2px;
            background-color: black;
        }
    }
    .expertise-card{
        width: 50%;
        padding: 0 50px 0 100px;
        @media (max-width: 1350px) {
            padding: 0 50px 0 50px;
        }
        @media (max-width: 1024px) {
            width: 100%;
            padding: 40px;
        }
    }
    .process-card{
        width: 50%;
        padding: 0 100px 0 50px;
        @media (max-width: 1350px) {
            padding: 0 50px 0 50px;
        }
        @media (max-width: 1024px) {
            width: 100%;
            padding: 0 40px;
        }
    }
    .top{
        display: flex;
        padding-bottom: 120px;
        @media (max-width: 1024px) {
            flex-direction: column;
            padding-bottom: 40px;
        }
    }
    .process{
        width: 87%;
        @media (max-width: 1362px) {
            width: 100%;
        }
        p{
            color: #6C6C6C;
            @media (max-width: 1362px) {
                font-size: 21px;
                line-height: 27px;
            }
            @media (max-width: 550px) {
                font-size: 18px;
                line-height: 21.47px;
            }
            @media (max-width:390px) {
                font-size: 17px;
                line-height: 20px;
            }
        }
    }
    .slider{
        display: flex;
        justify-content: space-between;
        height: 70%;
        width: 100%;
        flex-wrap: wrap;
        padding: 0 100px;
        @media (min-width: 1600px) {
            height: 70%;
            width: 100%;
        }
        @media (max-width: 1350px) {
            padding: 0 50px;
        }
        @media (max-width: 1024px) {
            height: 80%;
            padding: 0;
        }
    }
    .content-process{
        width: 32%;
        background-color: black;
        padding: 30px;
        position: relative;
        transition: background-color 0.5s ease;
        cursor: context-menu;
        @media (min-width: 1600px) {
            /* height: 500px;
            padding: 30px 50px; */
        }
        /* @media (max-width:1024px) {
            width: 48%;
            height: 65%;
            &:nth-child(3){
                width: 100%;
                height: 30%;
            }
        } */
        @media (max-width: 1024px) {
            width: 100%;
            height: 90%;
            margin: 0 0 1.5rem;
            &:nth-child(3){
                margin: 0;
            }
        }
        h3{
            font-size: 30px;
            margin-bottom: 30px;
            font-family: "NeueHaasDisplayMedium";
        }
        h3,p{
            color: white;
        }
        p{
            margin-bottom: 20px;
            font-size: 18px;
            line-height: 21.47px;
            @media (min-width: 1600px) {
                font-size: 20px;
                line-height: 23.47px;
            }
            @media (max-width:390px) {
                font-size: 17px;
                line-height: 20px;
            }
        }
    }
`
const ContentServDetail = styled.div`
    width: 100%;
    padding: 0 100px;
    background-color: #F1F0EB;
    @media (max-width: 1350px) {
        padding: 0 50px;
    }
    @media (max-width: 1024px) {
        padding: 40px 40px 0;
    }
    h2{
        padding: 0 0 25px 0;
        color: black;
        font-family: "NeueHaasDisplayMedium";
        display: flex;
        flex-direction: column;
        &::after{
            content: ' ';
            margin-top: 10px;
            width: 100px;
            height: 2px;
            background-color: black;
        }
    }
    .content-services{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding-bottom: 120px;
        width: 872px;
        @media (min-width: 1600px) {
            width: 70%;
        }
        @media (max-width: 1024px) {
            width: 100%;
            padding-bottom: 40px;
        }
        p{
            padding-bottom: 5px;
            color: #6C6C6C;
        }
    }
    .services{
        width: 100%;
        .card{
            width: 100%;
            padding: 0 0 120px 0;
            display: flex;
            justify-content: space-between;
            align-items: center;
            position: relative;
            overflow: hidden;
            @media (max-width: 1024px) {
                flex-direction: column;
                padding: 0 0 60px 0;
            }
            .img,.text{
                width: 50%;
                height: 100%;
                @media (max-width: 1024px) {
                    width: 100%;
                }
            }
            .img{
                height: 400px;
                padding-right: 30px;
                position: relative;
                @media (max-width: 1024px) {
                    padding: 0 0 20px;
                    height: 540px;
                }
                @media (max-width: 860px) {
                    height: 440px;
                }
                @media (max-width:550px) {
                    padding: 0;
                    margin-bottom: 20px;
                    height: 200px;
                }
                a{
                    position: absolute;
                    display: block;
                    width: 100%;
                    height: 100%;
                    z-index: 9;
                }
                .vimeo{
                    /* position: absolute; */
                    width: 100%;
                    height: 100%;
                    display: flex;
                    iframe{
                        position: absolute;
                    }
                }
            }
            video{
                width: 100%;
                height: 100%;
                /* object-fit: contain !important; */
            }
            &.reverse{
                flex-direction: row-reverse;
                @media (max-width: 1024px) {
                   flex-direction: column;
                }
                .text{
                    padding-left: 0;
                    padding-right: 30px;
                    @media (max-width: 1024px) {
                        padding: 0;
                    }
                }
                .img{
                    padding-right: 0;
                    padding-left: 30px;
                    @media (max-width:1024px) {
                        /* padding: 0 0 20px; */
                        padding: 0;
                    }
                }
            }
            .text{
                height: 80%;
                display: flex;
                flex-direction: column;
                justify-content: space-evenly;
                align-items: flex-start;
                padding-left: 30px;
                position: relative;
                /* width: 80%; */
                @media (max-width: 1024px) {
                    padding: 0;
                }
                h3{
                    font-family: "NeueHaasDisplayMedium";
                    font-weight: bold;
                    color: black;
                    padding-bottom: 20px;
                    @media (max-width: 1024px) {
                        padding-bottom: 10px;
                    }
                }
                p{
                    color: #6C6C6C;
                    /* font-size: 24px; */
                    padding-bottom: 20px;
                    @media (min-width: 1600px) {
                        width: 700px;
                    }
                    /* @media (max-width: 1362px) {
                        font-size: 21px;
                        line-height: 27px;
                    } */
                }
            }
        }
    }
`
const ContentClientes = styled.div`
    width: 100%;
    height: 100%;
    padding: 120px 120px;
    background-color: ${props => props.white ? "#F1F0EB" : "black"};
    display: flex;
    flex-direction: column;
    align-items: center;
    @media (max-width: 1024px) {
        padding: 40px;
        align-items: flex-start;
    }
    h2{
        color: ${props => props.white ? "black" : "white"};
        font-family: "NeueHaasDisplayMedium";
        padding: 0;
        padding-bottom: ${props => props.white ? 0 : "120px"};
        @media (max-width: 1024px) {
            width: 40%;
            padding-bottom: 30px;
        }
        @media (max-width: 550px) {
            padding-bottom: 30px;
            width: 80%;
        }
    }
    img{
        width: 100%;
    }
`
const ContentClientsSay = styled.div`
    width: 100%;
    height: 594px;
    padding: 120px 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    @media (max-width: 1024px) {
        display: none;
    }
    h2{
        /* font-size: 40px; */
        padding: 0;
        color: black;
        font-family: "NeueHaasDisplayMedium";
    }
    .swiper{
        width: 100%;
        height: 90%;
        @media (max-width: 550px) {
           height: 80%;
        }
    }
    .swiper-horizontal>.swiper-pagination-bullets, .swiper-pagination-bullets.swiper-pagination-horizontal, .swiper-pagination-custom, .swiper-pagination-fraction{
        bottom: 0;
    }
    .swiper-pagination-bullet{
        background-color: #D9D9D9;
        width: var(--swiper-pagination-bullet-width,var(--swiper-pagination-bullet-size,15px));
        height: var(--swiper-pagination-bullet-height,var(--swiper-pagination-bullet-size,15px));
    }
    .swiper-pagination-bullet-active{
        background-color: black;
    }
    .swiper-slide{
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .referencia{
        width: 80%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        @media (max-width: 550px) {
           width: 100%;
        }
        p{
            /* font-size: 24px; */
            color: #6C6C6C;
            text-align: center;
            
            /* @media (min-width: 1600px) {
                font-size: 26px;
            } */
            @media (max-width: 1362px) {
                font-size: 21px;
                line-height: 27px;
            }
            &:nth-child(2){
                font-family: "HelveticaNeuemedium";
              
            }
        }
    }
`
const ContentPhilosophy = styled.div`
    width: 100%;
    padding: 120px 100px;
    position: relative;
    background-color: #F1F0EB;
    display: flex;
    flex-direction: column;
    @media (max-width: 1350px) {
        padding: 120px 80px;
    }
    @media (max-width: 1024px) {
        padding: 40px;
    }
    h2{
        padding: 0 0 25px 0;
        color: black;
        font-family: "NeueHaasDisplayMedium";
        display: flex;
        flex-direction: column;
        &::after{
            content: ' ';
            margin-top: 10px;
            width: 100px;
            height: 2px;
            background-color: black;
        }
    }
    .philosophy{
        width: 74%;
        padding-bottom: 50px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        @media (max-width: 1024px) {
           height: 90%;
           width: 100%;
           padding-bottom: 0;
        }
        a{
            text-decoration: none;
            color: black;
        }
        p{
            color: #6C6C6C;
            padding-bottom: 35px;
            span{
                font-family: "NeueHaasDisplayMedium";
            }
        }
    }
`
const ContentTeam = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    @media (max-width: 1024px) {
        height: 500px;
    }
    img{
        width: 100%;
        height: 100%;
        object-fit: cover;
        @media (max-width: 1024px) {
            object-position: left;
        }
    }
`
const ContentQuestions = styled.div`
    width: 100%;
    height: auto;
    padding: 120px 100px;
    background-color: #F1F0EB;
    @media (max-width: 1350px) {
        padding: 120px 80px;
    }
    @media (max-width: 1024px) {
        padding: 40px;
    }
    h2{
        padding: 0 0 25px 0;
        color: black;
        font-family: "NeueHaasDisplayMedium";
        display: flex;
        flex-direction: column;
        &::after{
            content: ' ';
            margin-top: 10px;
            width: 100px;
            height: 2px;
            background-color: black;
        }
    }
    .questions{
        ul{
            width: 846px;
            @media (max-width: 1024px) {
                 width: 100%;
            }
            li{
                list-style: none;
                font-size: 23px;
                font-weight: 600;
                font-family: "HelveticaNeue";
                cursor: pointer;
                padding: 10px 0;
                color: black;
                @media (max-width: 1024px) {
                    font-size: 18px;
                }
                @media (max-width:390px) {
                    font-size: 16px;
                    line-height: 20px;
                }
                div{
                    height: auto;
                    max-height: 0;
                    overflow: hidden;
                    transition: max-height 1s ease;
                    p{
                        color: #6C6C6C;
                        padding: 10px 0 50px;
                        @media (max-width: 550px) {
                            padding: 10px 0 10px;
                        }
                    }
                   
                }
                &:hover div{
                    height: auto;
                    max-height: 400px;
                    /* &:nth-child(2){
                        max-height: 500px;
                    } */
                }
                &:nth-child(2){
                    div{
                        p{
                            padding: 0;
                           &:nth-child(1){
                            padding: 10px 0 0;
                           }
                           &:nth-child(8){
                            padding: 0 0 50px;
                           }
                        }
                    }
                    &:hover {
                       div{
                        max-height: 500px;
                        @media (max-width: 550px) {
                            max-height: 600px;
                        }
                       }
                    }
                }
            }
        }
    }
`
const ContentFooter = styled.div`
    width: 100%;
    height: 646px;
    padding: 0 28px 28px;
    background-color: white;
    @media (max-width: 1024px) {
        padding: 0px;
        height: 640px;
        background-color: black;
    }
    @media (max-width: 550px) {
        height: 876px;
    }
    .top,.bottom{
        background-color: black;
        width: 100%;
        height: 50%;
        @media (max-width: 1024px) {
           height: 60%;
        }
    }
    .top{
        display: flex;
        justify-content: space-between;
        padding: 120px 100px 0;
        @media (max-width: 1350px) {
            padding: 120px 80px 0;
        }
        @media (max-width: 1024px) {
            padding: 40px 40px 0;
            height: auto;
        }
        .left{
            display: flex;
            width: 100%;
            height: 100%;
            flex-direction: column;
            justify-content: flex-start;
            @media (max-width: 1024px) {
                align-items: flex-start;
            }
            h2{
                padding: 0 0 20px 0;
                /* font-size: 40px; */
                font-family: "NeueHaasDisplayMedium";
                color: white;
            }
            p{
                color: white;
                @media (max-width: 1024px) {
                   width: 65%;
                }
                @media (max-width: 550px) {
                    width: 100%;
                }
                /* @media (max-width: 1362px) {
                    font-size: 21px;
                    line-height: 27px;
                } */
            }
            .row{
                display: flex;
                align-items: center;
                justify-content: space-between;
                @media (max-width: 1024px) {
                   width: 100%;
                }
                a{
                    padding: 20px;
                    background-color: #FFFF02;
                    text-decoration: none;
                    color: black;
                    font-size: 20px;
                    transition: background-color 0.2s ease;
                    font-weight: 600;
                    cursor: pointer;

                    &:hover{
                        background-color: #FBF970;
                    }
                }
            }
            .social{
                display: flex;
                width: 50px;
                padding: 20px 0;
                justify-content: space-between;
                img{
                    width: 20px;
                }
                a{
                    color: white;
                }
            }
            .button_mobile{
                    padding: 20px;
                    background-color: #FFFF02;
                    text-decoration: none;
                    color: black;
                    font-size: 20px;
                    transition: background-color 0.2s ease;
                    font-weight: 600;
                    cursor: pointer;
                    margin-top: 20px;
                    @media (max-width:550px) {
                        font-size: 18px ;
                        padding: 18px;
                    }
                    &:hover{
                        background-color: #FBF970;
                    }
                }
        }
        .right{
            display: flex;
            align-items: center;
            justify-content: center;
            display: none;
            @media (max-width: 550px) {
                width: 100%;
            }
            a{
                padding: 5px 15px;
                background-color: transparent;
                border-radius: 50px;
                border: 1px solid black;
                text-decoration: none;
                color: white;
                font-weight: bold;
                transition: background-color 0.2s ease;
                cursor: pointer;
                font-size: 30px;
                &:hover{
                    transform: scale(1.02);
                    box-shadow: 0 0 4px 7px rgb(254 238 19 / 15%);
                }
            }
        }
    }
    .bottom{
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        padding: 0 100px 120px;
        @media (max-width: 1350px) {
            padding: 0 80px 120px;
        }
        @media (max-width: 1024px) {
            flex-direction: column;
            padding: 40px;
        }
        @media (max-width: 550px) {
            flex-direction: column;
            height: 65%;
            padding: 40px;
        }
        .address{
            display: flex;
            @media (max-width: 1024px) {
                width: 100%;
            }
            @media (max-width: 550px) {
                flex-direction: column;
            }
            .city{
                width: 158px;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                height: 106px;
                margin-right: 30px;
                @media (max-width: 1420px) {
                    margin-right: 5px;
                }
                /* @media (max-width: 1362px) {
                    margin-right: 5px;
                } */
                @media (max-width: 550px) {
                    justify-content: flex-start;
                    margin-bottom: 40px;
                }
                p{
                    font-size: 16px;
                    font-weight: bold;
                    margin-bottom: 20px;
                    color: white;
                    @media (max-width: 550px) {
                        margin-bottom: 0;
                    }
                }
                a{
                    text-decoration: none;
                    font-size: 16px;
                    color: white;
                    cursor: pointer;
                    @media (max-width: 550px) {
                        font-size: 17px;
                    }
                    &:nth-child(4){
                        text-decoration: underline;
                    }
                }
                &:nth-child(3){
                    margin-left: 40px;
                    @media (max-width: 1420px) {
                        margin-left: 20px;
                    }
                    /* @media (max-width: 1362px) {
                        margin-left: 20px;
                    } */
                    @media (max-width: 550px) {
                        margin-left: 0;
                    }
                }
            }
            img{
                width: 120px;
                height: 106px;
                object-fit: contain;
                @media (max-width: 550px) {
                    display: none;
                }
            }
        }
        .datos{
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: flex-end;
            height: 106px;
            @media (max-width: 1024px) {
                align-items: flex-start;
                height: auto;
                width: 100%;
            }
            a{
                color: white;
                text-decoration: none;
                font-family: "NeueHaasDisplayLight";
                cursor: pointer;
            }
            p{
                color: white;
            }
            .icono-footer{
                height: 40px;
                @media (max-width: 550px) {
                    height: auto;
                }
            }
            img{
                width: 100px;
                @media (min-width: 1600px) {
                    width: 120px;
                }
                @media (max-width: 550px) {
                    width: 102px;
                    padding: 40px 0;
                }
            }
            .subscribe{
                    font-size: 17px;
                    font-weight: bold;
                    letter-spacing: -0.03em;
                    cursor: pointer;
                    @media (max-width: 1362px) {
                        font-size: 15px;
                    }
                    @media (max-width: 550px) {
                        font-size: 18px;
                    }
                    /* &:nth-child(2){ */
                        display: flex;
                        flex-direction: column;
                        &::after{
                            content: ' ';
                            width: 55px;
                            height: 2px;
                            background-color: #FFFF02;
                            transition: width 0.5s ease;
                        }
                        &:hover{
                            &::after{
                                width: 75px;
                            }
                        }
                    /* } */
            }
            div{
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                align-items: flex-end;
                @media (max-width: 1024px) {
                    align-items: flex-start;
                }
                p{
                    display: flex;
                    font-size: 14px;
                    text-align: end;
                    line-height: normal !important;
                    @media (max-width: 1362px) {
                        text-align: start;
                    }
                    @media (max-width: 550px) {
                        width: 100%;
                        display: block;
                    }
                    a{
                        font-weight:normal;
                        font-size: 14px;
                        @media (max-width: 1362px) {
                            font-size: 11px;
                        }
                        @media (max-width: 550px) {
                            font-size: 14px;
                        }
                    }
                    span{
                        margin: 0 5px;
                    }
                }
                .subscribe{
                    font-size: 17px;
                    font-weight: bold;
                    letter-spacing: -0.03em;
                    cursor: pointer;
                    @media (max-width: 1362px) {
                        font-size: 15px;
                    }
                    &:nth-child(2){
                        display: flex;
                        flex-direction: column;
                        @media (max-width: 1024px) {
                            margin: 20px 0;
                        }
                        &::after{
                            content: ' ';
                            width: 55px;
                            height: 2px;
                            background-color: #FFFF02;
                            transition: width 0.5s ease;
                        }
                        &:hover{
                            &::after{
                                width: 75px;
                            }
                        }
                    }
                }
            }
        }
    }
`
const Button = styled.a`
    text-decoration: none;
    font-family: ${props => props.font ? "HelveticaNeue" :"HelveticaNeuemedium"} ;
    position: ${props => props.absolute ? "absolute" : "relative"} ;
    padding: ${props => props.padding ? '10px 0' : '17px'} ;
    background-color: ${props => props.subrayado ? "transparent" : "#FFFF02"};
    color: ${props => props.black ? "white" : "black"};
    cursor: pointer;
    transition: background-color 0.5s ease;
    display: flex;
    flex-direction: column;
    font-size: ${props => props.size ? '20px' : '23px'} ;
    font-weight: ${props => props.weight ? 400 : 700};
    bottom: ${props => props.portada ? 0 : props => props.servicios ? '-40px' : props => props.mobile ? '-10px' :  props => props.philosophy ? '20px' : props => props.mobile2 ? '30px' :'110px' };
    z-index: 9;
    &:hover{
        background-color: ${props => props.opacity ? '#FBF970' : 'transparent'} ;
        //transform: scale(1.02);
        /* box-shadow: 0 0 4px 7px rgb(254 238 19 / 15%);  */
        ~.subrayado{
            height: 20px;
        }
    }
    a{
        font-family: ${props => props.font ? "HelveticaNeue" :"HelveticaNeuemedium"} ;
        font-size: ${props => props.size ? '18px' : '23px'} ;
        font-weight: ${props => props.weight ? 400 : 700};
        @media (max-width:550px) {
            font-size: ${props => props.size ? '18px' : '20px'} ;
        }
        @media (max-width:390px) {
            font-size: ${props => props.size ? '18px' : '19px'} ;
        }
        padding: 0 15px;
        &::after{
            content: ' ';
            width: 100%;
            height: 15px;
            background-color: #FFFF02;
            position: absolute;
            z-index: -1;
            bottom: 0;
            left: 0;
            transition: height 0.5s ease-in-out;
        }
        &:hover{
            &::after{
                height: 20px;
            } 
        }
    }
    @media (max-width:550px) {
        padding: ${props => props.padding ? '10px 0' : '18px'} ;
    }
`
const Loading = styled.div`
    width: 100%;
    height: 100vh;
    position: fixed;
    background-color: black;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999999;
    &.hide{
        display: none;
    }
    img{
        width: 350px;
    }
    video{
        width: 180px;
        @media (max-width: 550px) {
            width: 150px;
        }
    }
`
const ModalVideo = styled.div`
    width: 100%;
    height: 100vh;
    position: fixed;
    z-index: 999;
    background-color: rgba(255, 255, 255, 0.882);
    overflow-y: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    p{
        position: absolute;
        font-size: 30px;
        right: 100px;
        top: 30px;
        color: black;
        cursor:pointer;
    }
    iframe{
        width: 80%;
        height: 90%;
    }
`
const FormContact = styled.div`
    width: 100%;
    background-color: #F1F0EB;
    padding: 120px 100px;
    margin-top:  1.75rem;
    @media (max-width: 1024px) {
        padding: 40px;
    }
    .title{
        display: flex;
        h2{
            padding: 0 0 25px 0;
            color: black;
            /* font-size: 40px; */
            font-family: "NeueHaasDisplayMedium";
            display: flex;
            flex-direction: column;
            @media (max-width: 550px) {
                padding: 0 0 30px 0;
            }
            &::after{
                content: ' ';
                margin-top: 10px;
                width: 100px;
                height: 2px;
                background-color: black;
            }

        }
    }
    p{
        color: #6C6C6C;
        width: 70%;
        @media (min-width: 1600px) {
            width: 55%;
        }
        @media (max-width: 550px) {
           width: 100%;
           margin-bottom: 10px;
        }
    }
    .recaptcha{
        margin-top: 100px;
    }
    form{
        display: flex;
        flex-direction: column;
        width: 60%;
        align-items: flex-start;
        color: red;
        font-family: "HelveticaNeue";
        @media (max-width: 550px) {
           width: 100%;
        }

        input{
            font-family: "HelveticaNeue";
            width: 600px;
            height: 40px;
            background-color: transparent;
            border: none;
            border-bottom: 1px solid #6C6C6C;
            font-size: 22px;
            outline: none;
            margin-top: 35px;
            &:-internal-autofill-selected, &:-webkit-autofill{
                background-color:  #F1F0EB !important;
            }
            @media (max-width: 550px) {
                width: 100%;
                font-size: 18px;
            }
        }
        input:-webkit-autofill,
        input:-webkit-autofill:hover, 
        input:-webkit-autofill:focus, 
        input:-webkit-autofill:active{
            -webkit-box-shadow: 0 0 0 30px #F1F0EB inset !important;
        }
        label{
            color: #6C6C6C;
            font-family: "HelveticaNeue";
            font-size: 22px;
            margin-top: 35px;
            @media (max-width: 550px) {
                width: 100%;
                font-size: 18px;
            }
        }
        select{
            width: 600px;
            font-family: "HelveticaNeue";
            background-color: transparent;
            border: 1px solid #6C6C6C;
            color: #6C6C6C;
            outline: none;
            font-size: 18px;
            padding: 0 20px;
            height: 40px;
            margin-top: 10px;
            @media (max-width: 550px) {
                width: 100%;
            }
        }
        #my-radio-group,#checkbox-group{
            color: #6C6C6C;
            margin-top: 30px;
            font-family: "HelveticaNeue";
            font-size: 22px;
            @media (max-width: 550px) {
                font-size: 18px;
            }
        }
        button{
            background-color: #FFFF02;
            padding: 20px;
            border: none;
            font-family: "HelveticaNeue";
            font-size: 22px;
            margin-top: 30px;
            @media (max-width: 550px) {
                font-size: 18px;
            }
        }
        input[type="radio"],input[type="checkbox"]{
            width: 20px;
            margin-top: 0;
            border-radius: 5px;
            margin-right: 10px;
            background-color: #F1F0EB;
            accent-color: white;
            border: #6C6C6C 1px solid;
        }
        .row{
            width: 100%;
            display: flex;
            flex-direction: column;
            p{
                font-size: 18px;
                width: auto;
                padding-left: 20px;
            }
        }
        .files{
            width: 600px;
            background-color: transparent;
            border: 1px solid #6C6C6C;
            padding:0 20px;
            margin: 30px 0 0;
            display: flex;
            height: 40px;
            justify-content: space-between;
            align-items: center;
            font-size: 18px;
            @media (max-width: 550px) {
                width: 100%;
            }
            img{
                width: 20px;
            }
        }
        input[type="file"]{
            display: none;
        }
        textarea{
            font-family: "HelveticaNeue";
            margin-top: 20px;
            width: 600px;
            height: 100px;
            background-color: transparent;
            border: none;
            font-size: 22px;
            outline: none;
            border: 1px solid #6C6C6C;
            resize: none;
            padding: 10px;
            @media (max-width: 550px) {
                width: 100%;
                font-size: 18px;
            }
        }
        .radiom,.checkbox{
            width: 600px;
            display: flex;
            flex-direction: column;
           justify-content: center;
           @media (max-width: 550px) {
                width: 100%;
            }
           label{
                margin-top: 0;
                display: flex;
                align-items: center;
                font-size: 18px;
           }
           
        }
    }
`
const TermsPrivacyStyle = styled.div`
    width: 100%;
    /* position: fixed;
    z-index: 999;
    background-color: rgba(0, 0, 0, 0.815); */
    /* display: flex;
    justify-content: center; */
    div{
        padding: 120px 100px;
        background-color: #F1F0EB;
        /* background-color: white;
        margin-top: 60px;
        padding: 30px;
        width: 80%;
        height: 80%;
        overflow-y: scroll; */
        @media (max-width: 1024px) {
            padding: 120px 40px;
        }
        h2{
            color: black;
             font-family: "NeueHaasDisplayMedium";
        }
        h4{
            font-size: 23px;
            font-family: "NeueHaasDisplayMedium";
            margin-bottom: 10px;
        }
        a{
            color: #6C6C6C;
            font-size: 23px;
            @media (max-width: 550px) {
                font-size: 18px;
            }
        }
        p{
            margin-bottom: 30px;
            color: #6C6C6C;
        }
        ul{
            margin-left: 20px;
            li{
                font-size: 23px;
                font-family: "HelveticaNeue";
                color: #6C6C6C;
                @media (max-width: 550px) {
                    font-size: 18px;
                }
            }
            margin-bottom: 30px;
        }
    }
`
const StikyTop = styled.div`
    cursor: pointer;
    width: 80px;
    z-index: 999;
    position: absolute;
    right: 20px;
    bottom: 60px;
    transition: transform 0.5s ease;
    @media (max-width: 550px) {
        bottom: 110px;
    }
    &:hover{
        transform: scale(1.15);
        -ms-transform: scale(1.15);
        -webkit-transform: scale(1.15);
       
    }
    img{
        width: 45px;
    }
    svg{
        fill: #fff !important;
    }
    .arrow{
        width: 30px;
        height: 30px;
        top: 50%;
        margin: -20px 0 0 -20px;
        -webkit-transform: rotate(45deg);
        border-right: none;
        border-bottom: none;
        border-left: 2px #fff solid;
        border-top: 2px #fff solid;
    }
`
const ThankYou = styled.div`
    width: 100%;
    height: 600px;
    background-color: #F1F0EB;
    display: flex;
    align-items: center;
    /* margin: 1.75rem; */
    padding: 100px;
    @media (max-width:550px) {
        padding: 30px;
    }
    h2{
        font-size: 50px;
        color: black;
        font-family: "NeueHaasDisplayMedium";
        @media (max-width:550px) {
            font-size: 30px;
        }
    }
    p{
        
        color: #6C6C6C;
        font-size: 28px;
        @media (max-width:550px) {
            font-size: 20px;
            width: 100%;
        }
    }
`
const ContentAssociated = styled.div`
    width: 100%;
    height: auto;
    padding: 100px 100px 50px;
    display: flex;
    column-gap: 20px;
    @media (max-width: 1024px) {
        padding: 50px 40px;
        flex-wrap: wrap;
        row-gap: 20px;
    }

    .card{
        width: 25%;
        height: 100%;
        display: flex;
        flex-direction: column;
        @media (max-width: 1024px) {
            width: 47%;
        }
        @media (max-width: 550px) {
            width: 100%;
        }
        img{
            width: 100%;
            height: 250px;
            object-fit: cover;
        }
        a{
            font-family: HelveticaNeuemedium;
            font-size: 16px;
            font-weight: 700;
            background-color: transparent;
            color: black;
            z-index: 1;
            width: fit-content;
            cursor: pointer;
            width: 100%;
            /* margin-top: 10px; */
            @media (max-width: 550px) {
                font-size: 18px;
            }

        }
        p{
                line-height: 20px;
            }
        .imagen_link{
            font-family: HelveticaNeuemedium;
            font-size: 16px;
            font-weight: 700;
            background-color: transparent;
            color: black;
            z-index: 1;
            width: fit-content;
            cursor: pointer;
            margin-top: 10px;
            vertical-align: top;
            background-image: linear-gradient(transparent 20%, var(--link-2) 10%);
            background-size: auto 175%;
            transition: background 0.8s ease-in-out;
            @media (max-width: 550px) {
                font-size: 18px;
            }

            &:hover{
                background-position-y: 100%;
            }
            &.moreTitle{
                &::after{
                    height: 30px;
                }
                &:hover{
                    &::after{
                        height: 40px;
                    }
                }
            }
        }
    }
`
const ContentPortafolio = styled.div`
    width: 100%;
    height: auto;
    padding: 1.75rem 1.75rem 0;
    img{
        width: 100%;
        margin-bottom: 30px;
        object-fit: cover;
        max-height: 1600px;
    }
    h3{
        color: #787878;
        font-family: "HelveticaNeue";
        font-weight: normal;
        font-size: 23px;
        @media (max-width:550px) {
            font-size: 15px;
        }
    }
    div{
        padding: 0 0 30px;
    }
`
export {
    ContentHeader,
    ContentPortada,
    ContentConcepto,
    ContentServices,
    ContentStudio,
    ContentProcess,
    ContentIndustries,
    ContentServDetail,
    ContentClientes,
    ContentClientsSay,
    ContentPhilosophy,
    ContentTeam,
    ContentQuestions,
    ContentFooter,
    ContentAssociated,
    ModalVideo,
    Video,
    Button,
    Loading,
    FormContact,
    TermsPrivacyStyle,
    StikyTop,
    ThankYou,
    ContentPortafolio
}