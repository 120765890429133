import React from 'react';
import { Filtrado } from './style';
const Filtros = (props) => {
    const {children} = props
    return ( 
        <Filtrado>
            {children}
        </Filtrado>
     );
}
 
export default Filtros;